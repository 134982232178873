<template>
        <section class="md:flex gap-4 items-start" id="AddError">
            <b-card class="w-full md:w-8/12">                
                <p class="leading-relaxed mb-1">Ingresa los detalles del error y sus respectivas instrucciones, <b>si el codigo existe, se actualizarán los datos.</b> </p>                
                <b-form-checkbox
                    id="sms"
                    v-model="msg"
                    name="sms"
                    class="mb-2"                    
                >
                    <span class="text-primary">Activar mensaje de texto (recuerda que tiene un costo, consulta antes de activarlo)</span>
                </b-form-checkbox>                
                <b-form-group
                    label="Nombre (Código)"
                    label-for="code"
                >
                    <b-form-input
                        id="code"
                        placeholder="Nombre (Código)"
                        v-model="code"
                    />
                </b-form-group>
                                                
                <b-form-group
                    label="Nivel de Error"
                    label-for="error"
                >
                    <b-form-select v-model="level" :options="levels" id="error"></b-form-select> 
                </b-form-group>               

                <b-form-group
                    label="Descripción Corta"
                    label-for="resume"
                >
                    <b-form-input
                        id="resume"
                        placeholder="Descripción Corta"
                        v-model="resume"
                    />
                </b-form-group>                
                <b-form-group
                    label="Descripción"
                    label-for="description"
                >
                    <b-form-textarea
                        id="description"
                        placeholder="Descripción"
                        v-model="description"
                        rows="3"
                        class="resize-none"
                    ></b-form-textarea>
                </b-form-group>                                                                    
                <b-form-group
                    label="Conclusión"
                    label-for="conclusion"
                >
                    <b-form-textarea
                        id="conclusion"
                        placeholder="Conclusión"
                        v-model="conclusion"
                        rows="3"
                        class="resize-none"
                    ></b-form-textarea>
                </b-form-group>
                <b-button @click="createError" v-if="!isEdit" variant="primary" class="mt-1">Crear Error</b-button>
                <b-button @click="updateError" v-if="isEdit" variant="success" class="mt-1">Guardar Cambios</b-button>
                <p class="text-xs text-gray-500 mt-1">Antes de crear el error, verifica que todos los datos sean correctos y los mas importantes (mensajes de texto y nombre)</p>                
            </b-card>
            <b-card class="w-full md:w-4/12">
                <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Instrucciones</h2>   
                <template v-for="(l, i) in inputs">
                    <instruction-block :key="i" :instruction="l" @delete="removeItem" :index="i"></instruction-block>               
                </template>                
                <b-button @click="addInput" variant="primary" class="mt-2">Agregar Instrucción</b-button>
            </b-card>
        </section>
</template>

<script>

function initialState () {
    return{
            title: 'Crear Error',
            code: '',
            description: '',
            inputs: [{ description: '', title:'' }],
            conclusion: '',
            resume: '',
            level: '',
            levels:[
                {text: 'Importante', value: 'hard'},
                {text: 'Medio', value: 'medium'},
                {text: 'Leve', value: 'easy'}
            ],
            author: '',
            msg: false,
            isEdit: false
        }
}
import {BButton, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BFormCheckbox, BCard} from 'bootstrap-vue'
import instructionBlock from './Components/instructionBlock.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
export default {
    data(){
        return initialState()
    },
    components:{
        'instruction-block' : instructionBlock,
        BButton, BFormGroup, BFormInput, BFormSelect,BFormTextarea,BFormCheckbox,BCard
    },
    methods:{
        addInput(){
            this.inputs.push({ description: '', title:'' })
        },
        createError(){
            firebase.database().ref('errors/'+ this.code).set({
                author: this.author,
                conclusion: this.conclusion,
                description: this.description,
                level: this.level,
                name: this.code,
                resume: this.resume,
                steps: this.inputs,
                msg: this.msg
            })
            this.$toast({
                component: ToastificationContent,
                props: {
                    title:'Equipo Agregado',
                    icon: 'PlusIcon',
                    variant: 'success',
                }
            })
            Object.assign(this.$data, initialState());
        },
        updateError(){
            firebase.database().ref('errors/'+ this.code).update({
                author: this.author,
                conclusion: this.conclusion,
                description: this.description,
                level: this.level,
                name: this.code,
                resume: this.resume,
                steps: this.inputs,
                msg: this.msg
            })
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Cambios Guardados',
                    icon: 'SaveIcon',
                    variant: 'success',
                }
            })
        },
        removeItem(event){           
            this.inputs.splice(event, 1)
        }
    },
    mounted(){
        this.author = firebase.auth().currentUser.email
        if (this.$route.meta.currentUserId) {
            firebase.database().ref('users/'+this.$route.meta.currentUserId+'/rol').once('value', (snap)=>{
            const v = snap.val()            
            if(v == 'colab') this.$router.push({ path: '/'})
        })
        }
        if(this.$route.params.idError){
            this.title = 'Editar Error', this.isEdit = true
            firebase.database().ref('errors/'+this.$route.params.idError).once('value', (s)=>{              
                const v = s.val()
                this.author = v.author
                this.conclusion = v.conclusion
                this.description = v.description
                this.level = v.level
                this.code = v.name 
                this.resume = v.resume
                this.inputs = v.steps
                this.msg = v.msg
            })
        }
    }
}
</script>