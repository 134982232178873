<template>
  <div
    v-if="instruction"
    class="border-b-2 pb-1 mt-2"
  >
    <b-form-group
      label="Titulo"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="instruction.title"
        placeholder="Titulo"
      />
    </b-form-group>
    <b-form-group
      label="Detalles"
      label-for="description"
    >
      <b-form-textarea
        id="description"
        v-model="instruction.description"
        placeholder="Detalles"
        rows="3"
        class="resize-none"
      />
    </b-form-group>
    <span
      class="block text-red-800 text-sm cursor-pointer text-right"
      @click="$emit('delete', index)"
    >Remover Instruccion</span>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'InstructionBlock',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  props: {
    instruction: Object,
    index: Number,
  },
}
</script>
